.block-titre{
    padding-top: 5rem;
    padding-bottom: 5rem;
    .col{
        h3{
            font-size: 2.8rem;
            i{
                display: block;
            }
        }
        p{
            font-size: 1.5rem;
        }
    }
}