@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

body{
    font-family: 'Manrope', sans-serif;
}

.bi-icon {
    font-size: 2rem; 
    color: cornflowerblue;
}

h1{
    position: absolute;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/* Content Text */
.row-content-text{
    .col-title{
        h2{
            font-family: 'Anton', sans-serif;
            font-weight: 600;
            border-bottom: 1px solid black;
        }
        h3{
            font-family: 'Anton', sans-serif;
        }
    }
    .col-text{
        h2, p {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }
    }
    .col-img{
        img.shadow{
            box-shadow: $box-shadow;
        }
    }
    .tags{
        text-align: center;
        span{
            padding: 0.75rem;
            margin: 0.5rem 0;
            background-color: $primary;
            color: white;
            display: inline-block;
            @include media-breakpoint-only(xs) { font-size: 1.2rem; }
            @include media-breakpoint-only(sm) { font-size: 1.2rem; }
            @include media-breakpoint-only(md) { font-size: 1.5rem; }
            @include media-breakpoint-only(lg) { font-size: 1.7rem; }
            @include media-breakpoint-only(xl) { font-size: 1.7rem; }
            @include media-breakpoint-only(xxl) { font-size: 1.7rem; }
        }
    }
}

.row-content-text-white{
    .col-title{
        h2{
            border-bottom: 1px solid white;
        }
    }
}

.modal-open{
    #myModalPopup{
        .modal-content{
            background-image: url('/assets/images/bg-popup.jpg');
            background-position-x: 0;
            background-position-y: 0;
            background-repeat: no-repeat;
            min-height: 450px;
            .col-content{
                padding: 1rem;
                background-color: rgba($color: #ffffff, $alpha: 0.35);
                .card{
                    border: 0;
                }
            }
        }
        .modal-content.no-size{
            background-position: -10vw;
        }
    }
}

#listItems{
    .row-listing{
        .row-annonces{
            .col-block{
                .card{
                    .card-body{
                        background-color: rgba(255,255,255,0.6);
                        .prix{
                            font-size: 1.1rem;
                            span{
                                background-color: $primary;
                                color: white;
                                font-weight: 600;
                                padding: 0.25rem 0.5rem;
                            }
                        }
                    }
                    .overlay{
                        background-color: rgba(0,0,0,0.35);
                        opacity: 0;
                        transition:  all 0.5s;
                        a{
                            i{
                                font-size: 5rem;
                                color: rgba(255,255,255,0.35);
                            }
                        }
                    }
                }
                .card:hover{
                    .overlay{
                        opacity: 1;
                        transition:  all 0.5s;
                    }
                }
            }
            .col-inline{
                .card{
                    .col-img{
                        .overlay{
                            background-color: rgba(0,0,0,0.35);
                            opacity: 0;
                            transition:  all 0.5s;
                            a{
                                padding-top: 20%;
                                i{
                                    font-size: 5rem;
                                    color: rgba(255,255,255,0.35);
                                }
                            }
                        }
                    }
                    .col-img:hover{
                        .overlay{
                            opacity: 1;
                            transition:  all 0.5s;
                        }
                    }
                    .col-text{
                        .card-body{
                            a{
                                color: $primary !important;
                            }
                        }
                    }
                }
                
            }
            .card{
                box-shadow: $box-shadow;
                border: 1px solid white;
            }
        }
        .etiquette{
            background-color: red;
            color: white;
            padding: 0.5rem;
        }
    }
}

.pagination{
    .page-item{
        .page-link{
            color: #ffffff !important;
            @include media-breakpoint-down(sm) {
                font-size: 1.3rem;
            }
        }
        .no-link{
            font-size: 1.5rem;
            line-height: 1.5;
            text-decoration: none;
            padding: 0 0.75rem;
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }
    .page-item.active{
        .page-link{
            background-color: $secondary !important;
            @include media-breakpoint-down(sm) {
                font-size: 1.3rem;
            }
        }
    }
}
