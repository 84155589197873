footer{
    padding: $content-padding;
    background-color: $black;
    color: white;

    .menu-footer a, .infos-footer li{
        color: white !important;
        @include media-breakpoint-only(xs) { 
            font-size: 1.5rem;
            line-height: 1.8; 
        }
        @include media-breakpoint-only(sm) { 
            font-size: 1.5rem;
            line-height: 1.8; 
        }
    }

    .links-footer{
        
        @include media-breakpoint-only(xs) { 
            font-size: 1.5rem;
            line-height: 1.8; 
        }
        @include media-breakpoint-only(sm) { 
            font-size: 1.5rem;
            line-height: 1.8; 
        }
        a{
            color: white !important;
        }
        .nav{
            li{
                color: white !important;
                a{
                    color: white !important;
                }
            }
        }
    }
}