.modal{
    .modal-fullscreen{
        .modal-content{
            background-color: rgba($color: (#000000), $alpha: 0.5);
            .modal-header{
                border-bottom: 0;
                .btn-close{
                    opacity: 1;
                }
            }
            .modal-body{
                .carousel{
                    margin-top: 0;
                    img {
                        height: 100%;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}