$blue:  #3366ff;
$blue-dark: #2049c6;
$redGrey: #746565;
$pink:    #d63384;
$red:     #d90000;
$red-dark:#b61717;
$orange:  #ff7400;
$yellow:  #ffe533;
$green:   #0e9375;
$teal:    #20c997;
$cyan:    #0dcaf0;
$taupe:   #483C32;
$brown:   #b55934;
$brown-dark:  #7c391e;
$green-yellow:  #bdba45;
$anise-green: #cacf8c;
$taupe-light: #b7a99c;
$yellow-gray: #8b8d82;

$white:    #fff;
$gray-100: #faf8f8;
$gray-200: #efecec;
$gray-300: #e6e0de;
$gray-400: #dad1ce;
$gray-500: #bdb1ad;
$gray-600: #7d706c;
$gray-700: #495057;
$gray-800: #403734;
$gray-900: #292321;
$black:    #1a1a1a;

$primary:       $gray-900;
$secondary:     $orange;
$success:       $blue-dark;
$info:          $red-dark;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$box-shadow:                  0 .3rem 1rem rgba($black, .5);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);

$border-white: 1px solid white;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);