#osm{
    padding: 0;
    background-color: $secondary;
    color: white;

    .osm-content{
        padding-top: 5rem;
        
        .col{
            padding-bottom: 5rem !important;
            h3{
                font-size: 2.8rem;
                i{
                    display: block;
                }
            }
            p{
                font-size: 1.2rem;
                text-align: left;
            }
        }

        .col p:nth-child(2){
            margin-top: 2rem;
        }
    }
    
}