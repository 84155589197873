#subShowcase{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    position: relative;
    z-index: 1020;
}

#subShowcase.subshowcase-mobile{
    .nav-link.disabled{
        color: black;
    }
}