#subFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: white;

    .content-info{
        box-shadow: $box-shadow;
        border: 1px solid white;
        margin: 5rem 3rem;
        padding: 3rem 2rem;
        background-color: $secondary;
        .col-title{
            h3{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 2rem;
            }
        }
        .col-text{
            p{
                font-size: 4rem;
            }
        }
    }

    .form-contact{
        .btn-primary{
            background-color: $black;
        }
    }
}